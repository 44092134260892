<template>
  <div class="">
    <h5 class="text-bold">{{$t('search.followers')}}</h5>

    <component
      :is="vue_slider"
      class="mt-1 avenir-medium px-1"
      v-model="followers"
      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :max="1000100"
      :min="0"
      :interval="100"
      @drag-end="changeSlide"
      :tooltip="'none'"
    >
      <template v-slot:dot="{ value }">
        <div :class="getClasses(value)"></div>
      </template>

      <template v-slot:process="{ style, index }">
        <div class="vue-slider-process custom-class" :style="[style, getProgressStyle(style, index)]">
        </div>
      </template>
    </component>

    <div class="row mt-1">
      <div class="col-6">
        <p class="text-left m-0">{{$t('search.from')}}</p>
        <component
          :is="cleave"
          v-model="from"
          class="form-control"
          :raw="false"
          :options="options.number"
          placeholder="1,000"
          @input="changeInput()"
          @blur="endChangeInput()"
        />
      </div>
      <div class="col-6">
        <p class="text-left m-0">{{$t('search.to')}}</p>
        <component
          :is="cleave"
          v-model="to"
          class="form-control"
          :raw="false"
          :options="options.number"
          placeholder="10,000"
          @input="changeInput()"
          @blur="endChangeInput()"
        />
      </div>
    </div>

    <div class="mt-1">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-for="(size, index) in sizes"
          :key="index"
          v-model="followers_selected"
          :aria-describedby="ariaDescribedby"
          :value="size.value"
          class="mt-1 avenir-medium form-ratio-followers-searcher"
        > 
          <div class="d-flex justify-content-between w-100">
            <div>{{$t(`search.${size.text}`)}}</div>
            <div class="float-right">{{ size.value_label }}</div>
          </div>
        </b-form-radio>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BFormRadio
} from 'bootstrap-vue';
import { trimNumber } from '@/libs/utils/formats';

export default {
  name: 'FollowersFilter',
  components: {
    BFormInput,
    BFormGroup,
    BFormRadio,
  },
  props: {
    followers_dad: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      vue_slider: null,
      cleave: null,
      followers: this.followers_dad.length > 0 ? this.followers_dad : [1000, 10000],
      from: 1000,
      to: 10000,
      followers_selected: null,
      sizes: [
        {"value": [1000, 10000], "text": "nano", value_label: '(1K-10K)'},
        {"value": [10000, 50000], "text": "micro", value_label: '(10K-50K)'},
        {"value": [50000, 500000], "text": "levelMedium", value_label: '(50K-500K)'},
        {"value": [500000, 1000000], "text": "macro", value_label: '(500K-1M)'},
        {"value": [1000000, null], "text": "mega", value_label: '(1M+)'}
      ],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
    }
  },
  created () {
  },
  async mounted() {
    this.vue_slider = (await import('vue-slider-component')).default;
    this.cleave = (await import('vue-cleave-component')).default;
  },
  methods: {
    emitChangeFollowers() {
      let from = isNaN(this.from) ? trimNumber(this.from) : this.from
      let to = isNaN(this.to) ? trimNumber(this.to) : this.to
      if (from === '') {
        from = null
      }

      if (to === '') {
        to = null
      }
      this.$emit('changeFollowers', {from, to})
    },
    formatRangeFollowers() {
      if (this.followers[0] !== 0 && this.followers[1] !== 1000100) {
        this.from = this.followers[0]
        this.to = this.followers[1]
      } else if (this.followers[0] > 0 && this.followers[1] === 1000100) {
        this.from = this.followers[0]
        this.to = null
      } else if (this.followers[0] === 0 && this.followers[1] < 1000100) {
        this.from = null
        this.to = this.followers[1]
      } else {
        this.from = null
        this.to = null
      }
    },
    changeSlide() {
      this.formatRangeFollowers()
      this.followers_selected = null
      this.emitChangeFollowers()
    },
    changeInput() {
      const from = isNaN(this.from) ? trimNumber(this.from) : this.from
      const to = isNaN(this.to) ? trimNumber(this.to) : this.to
      if (from >= to) {
        this.followers = [to, from]
      } else if (to <= from) {
        this.followers = [to, from]
      } else {
        this.followers = [from, to]
      }
    },
    endChangeInput() {
      const old_from = isNaN(this.from) ? trimNumber(this.from) : this.from
      const old_to = isNaN(this.to) ? trimNumber(this.to) : this.to
      let from = isNaN(this.from) ? trimNumber(this.from) : this.from
      let to = isNaN(this.to) ? trimNumber(this.to) : this.to

      if (to && from && from > to) {
        from = old_to
        to = old_from
      } else if (from && to && to < from) {
        from = old_to
        to = old_from
      }

      this.followers_selected = null

      this.emitChangeFollowers()
    },
    getClasses(value) {
      if (value === 0 || value === 1000100) return 'value-inactive'
      return 'value-active'
    },
    getProgressStyle(style) {
      if (this.followers[0] !== 0 && this.followers[1] !== 1000100) {
        return {
          'left': style.left,
          'width': style.width,
          'background-color': '#7367f0'
        }
      } else if (this.followers[0] === 0 && this.followers[1] !== 1000100) {
        const width = 100 - parseFloat(style.width)
        return {
          'left': style.width,
          'width': `${width}%`,
          'background-color': '#7367f0'
        }
      }

      const left = this.followers[1] === 1000100 ? 0 : style.width;
      const width = this.followers[0] === 0 ? style.width : style.left;
      return {
        left,
        width,
        'background-color': '#7367f0'
      }
    }
  },
  watch: {
    followers_selected() {
      if (this.followers_selected) {
        this.followers = this.followers_selected
        this.from = this.followers_selected[0]
        this.to = this.followers_selected[1]
        this.emitChangeFollowers()
        this.update_input_commas = !this.update_input_commas;
      }
    },
    followers_dad() {
      this.followers = this.followers_dad.length > 0 ? this.followers_dad : [1000, 10000];
    },
  }
}
</script>

<style>
.form-ratio-followers-searcher .custom-control-label {
  width: 100%;
}
  .text-bold {
    font-weight: bold;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
</style>